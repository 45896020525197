import React, { useState, useEffect, useContext } from "react";
import { makeStyles } from "@material-ui/core/styles";
import api from "../../services/api";
import { AuthContext } from "../../context/Auth/AuthContext";
import Board from "react-trello";
import { toast } from "react-toastify";
import { TextField, Tabs, Tab, Select, MenuItem, Button } from "@material-ui/core";
import { i18n } from "../../translate/i18n";
import LaneTitle from "../../components/Kanban/LaneTitle";
import CardTitle from "../../components/Kanban/CardTitle";
import FooterButtons from "../../components/Kanban/FooterButtons";
import { Autocomplete } from '@mui/material';
import "./responsive.css";

const useStyles = makeStyles(theme => ({
  root: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    padding: theme.spacing(1),
    backgroundColor: theme.palette.background.default,
    color: theme.palette.text.primary,
    height: "auto",
    //overflow: "hidden",
  },
  searchBar: {
    marginBottom: theme.spacing(2),
    marginTop: theme.spacing(3),
    width: "80%",
    backgroundColor: theme.palette.background.paper,
    borderRadius: "4px",
  },
  tabs: {
    marginBottom: theme.spacing(2),
    width: "100%",
    backgroundColor: theme.palette.background.paper,
    borderRadius: "5px",
   
  },
  adminControls: {
    display: "flex",
    alignItems: "center",
    //gap: theme.spacing(2),
    marginTop: theme.spacing(4),
    width: "80%",
  },
  boardContainer: {
    width: "100%",
    height: "100%",
    overflowY: "auto",
    padding: theme.spacing(1),
    //scroll: "auto"
  },
}));

const Kanban = () => {
  const classes = useStyles();
  const { user } = useContext(AuthContext);
  const profile = user.profile;


  const [tags, setTags] = useState([]);
  const [tickets, setTickets] = useState([]);
  const [file, setFile] = useState({ lanes: [] });
  const [selectedTab, setSelectedTab] = useState("all");
  const [searchText, setSearchText] = useState("");
  const [users, setUsers] = useState([]);
  const [selectedUser, setSelectedUser] = useState(null);
  const [isFetching, setIsFetching] = useState(false);
  const [searchTerm, setSearchTerm] = useState("");


  const jsonString = user.queues.map(queue => queue.UserQueue.queueId);

  const fetchTags = async () => {
    try {
      const response = await api.get("/tags/kanban", {
        params: { userId: selectedUser || user.id },
      });
      const fetchedTags = response.data.lista || [];
      if (fetchedTags.length === 0) {
        toast.warn("Nenhuma TAG cadastrada no CRM.");
        setTags([]);
      } else {
        setTags(fetchedTags);
      }
      await fetchTickets();
    } catch (error) {
      console.error(error);
    }
  };
  useEffect(() => {
    //fetchTags();
    if (profile === "admin") fetchUsers();
  }, []);


  const handleCardMove = async (cardId, sourceLaneId, targetLaneId, index) => {
    try {
      await api.delete(`/ticket-tags/${targetLaneId}`);
      await api.put(`/ticket-tags/${targetLaneId}/${sourceLaneId}/${index}`);
      await fetchTickets(jsonString);
      //popularCards(jsonString);
    } catch (err) {
      console.log(err);
    }
  };

  const handleCardDelete = async (cardId, sourceLaneId, targetLaneId) => {
    try {
      await api.delete(`/ticket-tags/${cardId}`);
      await fetchTickets();
      //popularCards(jsonString);
    } catch (err) {
      console.log(err);
    }
  };

  const fetchUsers = async () => {
    try {
      const { data } = await api.get("/users/");
      setUsers(data.users || []);
    } catch (err) {
      console.error(err);
      toast.error("Erro ao buscar usuários.");
    }
  };

  const fetchTickets = async () => {
    try {
      const { data } = await api.get("/ticket/kanban", {
        params: { queueIds: JSON.stringify(jsonString) },
      });
      setTickets(data.tickets);
    } catch (err) {
      console.error(err);
      setTickets([]);
    }
  };

  //const handleUserChange = event => {
    //console.log(event)
    //setSelectedUser(event.id);
   // await fetchTags();
  //};

  const handleUserChange = async (event) => {
    setSelectedUser(event.id);
    //await fetchTags();
   
  };

  const popularCards = () => {
    let filteredTickets = tickets;

    // Filtrar pelo texto de busca
    if (searchText.trim() !== "") {
      filteredTickets = filteredTickets.filter(ticket =>
        ticket.contact?.name?.toLowerCase().includes(searchText.toLowerCase())
      );
    }

    // Filtrar pela aba selecionada
    if (selectedTab !== "all") {
      filteredTickets = filteredTickets.filter(ticket =>
        ticket.tags?.some(tag => tag.id.toString() === selectedTab)
      );
    }

    const lanes = tags
      .filter(tag => selectedTab === "all" || tag.id.toString() === selectedTab)
      .map(tag => {
        const laneTickets = filteredTickets.filter(ticket =>
          ticket.tags?.some(t => t.id === tag.id)
        );
        return {
          id: tag.id.toString(),
          title: tag.name,
          label: laneTickets.length.toString(),
          cards: laneTickets.map(ticket => ({
            id: ticket.id.toString(),
            title: <CardTitle ticket={ticket} userProfile={user.profile} />,
            description: <FooterButtons ticket={ticket} />,
            draggable: true,
            href: "/tickets/" + ticket.uuid,
          })),
          style: { backgroundColor: tag.color, color: "white" },
        };
      });

    setFile({ lanes });
  };

  useEffect(() => {
    fetchTags();
  }, []);

  useEffect(() => {
    popularCards(); // Chama a função inicialmente
    const interval = setInterval(() => {
      fetchTickets();
      popularCards(); // Chama a função a cada 10 segundos
    }, 10000);
  
    return () => clearInterval(interval); // Limpa o intervalo ao desmontar o componente
  }, [tags, tickets, searchText, selectedTab]); // Deixa o array de dependências vazio para rodar apenas uma vez
  
  // Outra useEffect para monitorar as dependências
  useEffect(() => {
    // Atualiza o estado quando tags, tickets, searchText ou selectedTab mudarem
    popularCards();
  }, [tags, tickets, searchText, selectedTab]);
  

  const handleTabChange = (event, newValue) => {
    setSelectedTab(newValue);
  };

  const handleSearchChange = event => {
    setSearchText(event.target.value);
  };

  const handleFetchKanban = async () => {
    if (!selectedUser) return toast.warn("Selecione um usuário.");
    setIsFetching(true);
    setSearchTerm(null);
    setSelectedTab("all");
    await fetchTags();
    setIsFetching(false);
  };

  return (
    <div className={classes.root}>

{profile === "admin" && (
  <div className={classes.adminControls}>
    <Autocomplete
      value={users.find((user) => user.id === selectedUser) || null}
      onChange={(event, newValue) => handleUserChange(newValue)}
      options={users}
      getOptionLabel={(option) => option.name}
      renderInput={(params) => (
        <TextField
          {...params}
          label="Selecione um usuário para visualizar o CRM"
          fullWidth
        />
      )}
      disableClearable
      isOptionEqualToValue={(option, value) => option.id === value.id}
      style={{ width: "100%" }}
    />
     <Button
            variant="contained"
            color="primary"
            onClick={handleFetchKanban}
            disabled={isFetching}
          >
            OK
          </Button>
   
  </div>
)}

      {/* Barra de busca */}
      <TextField
        className={classes.searchBar}
        label="Buscar por contato"
        variant="outlined"
        value={searchText}
        onChange={handleSearchChange}
      />

      {/* Abas de filtro */}
      <Tabs
        className={classes.tabs}
        value={selectedTab}
        onChange={handleTabChange}
        indicatorColor="primary"
        textColor="primary"
        variant="scrollable"
        scrollButtons="auto"
      >
        <Tab label="Todos" value="all" />
        {tags.map(tag => (
          <Tab key={tag.id} label={tag.name} value={tag.id.toString()} />
        ))}
      </Tabs>

      {/* Kanban Board */}
      <div className={classes.boardContainer}>
        {file.lanes.length > 0 ? (
          <Board
            data={file}
            onCardMoveAcrossLanes={handleCardMove}
            onCardDelete={handleCardDelete}
            style={{
              backgroundColor: "transparent",
              height: "auto",
              width: "100%",  // Largura vai ocupar 100% do seu container pai
              overflowX: "auto",
              overflowY: "hidden",  
            }}
          />
        ) : (
          <p>Nenhuma tag cadastrada, cadastre suas tags, vincule ao CRM e atribua ao tickets para gerenciamento de crm. </p>
        )}
      </div>
    </div>
  );
};

export default Kanban;
