import React from 'react';
import { makeStyles } from "@material-ui/core";

const useStyles = makeStyles(theme => ({
    kanbanSquare: {
        width: "1.2rem",
        height: "1.2rem",
        borderRadius: "5px"
      },
      container: {
        display: "flex",
        justifyContent: "space-between", // Espaço entre os elementos (título e quantidade)
        alignItems: "center",
        gap: "8px", // Espaçamento interno entre o quadrado e o título
        padding: "0 16px", // Espaçamento das margens esquerda e direita
      },
      title: {
        fontSize: "0.875rem",
        fontWeight: "500", // Texto com peso médio
        color: "#111b21", // Cor semelhante ao texto principal do WhatsApp
        fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
        flexGrow: 1, // Faz o título ocupar o espaço restante disponível
        textAlign: "left", // Garante alinhamento à esquerda
      },
      quantity: {
        fontSize: ".75rem",
        fontWeight: "normal",
        color: "#3b4a54", // Cinza escuro para texto secundário
        backgroundColor: "#e1f3fb", // Fundo azul claro semelhante ao WhatsApp
        padding: "2px 8px",
        borderRadius: "16px",
        fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
        textAlign: "right", // Alinha a quantidade à direita
      }
}));

const LaneTitle = ({ squareColor, firstLane, children, quantity }) => {
    const classes = useStyles();

    return (
        <div className={classes.container}>
            {/* Quadrado colorido, ou espaço vazio */}
            {!firstLane ? (
                <div
                    className={classes.kanbanSquare}
                    style={{ backgroundColor: squareColor }}
                ></div>
            ) : (
                <div style={{ height: "1.2rem" }}></div>
            )}

            {/* Título do Kanban */}
            <div className={classes.title}>{children}</div>

            {/* Contador com estilo semelhante ao WhatsApp */}
            <div className={classes.quantity}>{quantity}</div>
        </div>
    );
};

export default LaneTitle;
